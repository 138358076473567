<template>
  <div>
    <v-container fluid>

      <Overlay :overlay="overlay"/>

      <v-card class="shadow rounded-lg">

        <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
          <v-col cols="12" lg="3">
            <v-text-field v-model.trim="search"
                          class="rounded-input"
                          clearable
                          dense
                          hide-details
                          outlined
                          placeholder="Recherche..." prepend-inner-icon="mdi-magnify"
                          single-line/>
          </v-col>
          <v-col class="text-end" cols="12" lg="9">
            <v-btn class="mr-2" color="primary" depressed @click="$refs.giftForm.open()">
              <v-icon left>mdi-plus</v-icon>
              Cadeau
            </v-btn>
            <v-btn color="primary" icon @click="getGifts">
              <v-icon>mdi-table-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider/>
        <v-card-text>
          <v-row v-if="isLoading">
            <v-col v-for="item in 6" :key="item" cols="12" lg="4">
              <v-card class="rounded-lg" outlined>
                <v-card-text>
                  <v-skeleton-loader type="article"></v-skeleton-loader>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <div v-else>

            <div v-if="!searchGifts.length">
              <v-card class="transparent" flat>
                <v-card-text>
                  <NoResult/>
                </v-card-text>
              </v-card>
            </div>

            <v-row v-else>
              <v-col v-for="(item,index) in searchGifts"
                     :key="index"
                     class="d-flex flex-column"
                     cols="12"
                     lg="4">
                <v-card class="rounded-lg flex d-flex flex-column" outlined>

                  <v-card-text class="flex">

                    <v-row align="center">
                      <v-col cols="4">
                        <v-img :src="$baseUrl + item.image"
                               class="zoom-pointer rounded"
                               max-height="60"
                               max-width="60"
                               @click="$refs.lightbox.open(item.image)"
                        ></v-img>
                      </v-col>
                      <v-col cols="8">

                        <h1 class="font-weight-medium fs-17 grey--text text--darken-3">
                          {{ item.name }}
                          <br>
                          <span v-if="item.is_digital" class="primary--text fs-12">
                            Digital ( {{ item.type === 'gifty_point' ? 'Gifty' : 'Flexy'}} )
                          </span>
                        </h1>

                        <p class="mt-2">
                          {{ item.description }}
                        </p>
                      </v-col>
                    </v-row>

                  </v-card-text>

                  <v-divider/>

                  <v-card-actions>

                    <v-spacer/>
                    <v-btn color="red" disabled icon @click="deleteGift(item.id,index)">
                      <v-icon size="20">mdi-trash-can</v-icon>
                    </v-btn>
                    <v-btn color="" icon @click="$refs.giftForm.open(item)">
                      <v-icon size="20">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </v-card-actions>

                </v-card>
              </v-col>
            </v-row>

          </div>
        </v-card-text>
      </v-card>


      <Lightbox ref="lightbox"/>
      <ConfirmDialog ref="confirmDialog"/>
      <GiftForm ref="giftForm" @refresh="getGifts"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import GiftForm from "@/views/gifts/components/GiftForm.vue";

export default {
    components: {GiftForm},
    data() {
        return {
            gifts: [],
            overlay: false,
            isLoading: false,
            search: '',
        }
    },

    methods: {
        getGifts() {
            this.isLoading = true
            HTTP.get('gifts').then(res => {
                this.gifts = res.data.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        async deleteGift(id, index) {
            if (await this.$refs.confirmDialog.open()) {
                this.overlay = true
                HTTP.delete('gifts/' + id).then(() => {
                    this.gifts.splice(index, 1)
                    this.overlay = false
                    this.$successMessage = 'Cet élément a été supprimé avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
    },
    computed: {
        searchGifts() {
            return this.gifts.filter(item => {
                if (this.search) {
                    return item.name.toLowerCase().includes(this.search.toLowerCase())
                        || item.description.toLowerCase().includes(this.search.toLowerCase())
                } else {
                    return item
                }
            });
        }
    },
    created() {
        this.getGifts()
    }
}
</script>

<style scoped>

</style>