<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">

      <v-card :disabled="saveLoading" :loading="saveLoading">

        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-text-field v-model="form.name"
                        :error-messages="errors.name"
                        dense
                        label="Nom *"
                        outlined>
          </v-text-field>

          <v-file-input ref="image"
                        :error-messages="errors.image"
                        accept="image/*"
                        chips
                        counter
                        dense
                        label="Image *"
                        outlined
                        show-size
                        small-chips
                        @change="uploadImage"
                        @click:clear="resetImage">
          </v-file-input>

          <v-textarea v-model="form.description"
                      :error-messages="errors.description"
                      dense
                      hide-details="auto"
                      label="Description"
                      outlined
                      rows="3">
          </v-textarea>

          <v-checkbox v-model="form.is_digital"
                      hide-details
                      label="Cadeau digital"
                      @change="form.is_digital ? form.type ='flexy' : form.type ='none'"/>

          <v-radio-group v-if="form.is_digital"
                         v-model="form.type"
                         :error-messages="errors.type"
                         label="Type *"
                         row>
            <v-radio class="d-none" label="none" value="none"></v-radio>
            <v-radio label="Flexy" value="flexy"></v-radio>
            <v-radio label="Point dans gifty" value="gifty_point"></v-radio>
          </v-radio-group>

        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn :loading="saveLoading"
                 color="secondary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            title: '',
            dialog: false,
            saveLoading: false,
            errors: {},
            form: {}
        }
    },
    methods: {
        uploadImage(file) {
            if (file) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.form.image = reader.result
                }
                reader.readAsDataURL(file)
            }
        },
        open(item) {
            this.resetImage()
            this.errors = {}
            this.dialog = true
            this.form = {
                id: item ? item.id : '',
                name: item ? item.name : '',
                description: item ? item.description : '',
                type: item ? item.type : 'none',
                is_digital: item ? item.is_digital : false,
                image: '',

            }
            this.title = item ? 'Modifier un cadeau' : 'Ajouter un cadeau'
        },
        save() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: this.form.id ? '/gifts/' + this.form.id : '/gifts',
                method: this.form.id ? 'put' : 'post',
                data: this.form,
            }).then(() => {
                if (this.form.id) {
                    this.dialog = false
                }
                this.$emit('refresh')
                this.$successMessage = 'Cet élément a ete enregistré avec succes'
                this.saveLoading = false
                this.resetImage()

                this.form = {
                    id: '',
                    name: '',
                    description: '',
                    type: 'none',
                    is_digital: false,
                    image: '',
                }

            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        resetImage() {
            if (this.$refs.image) {
                this.$refs.image.reset()
            }
            this.form.image = ''
        }
    }
}
</script>

<style scoped>

</style>